<template>
  <ValidationProvider
    v-if="view"
    slim
    :name="view.props.name"
    :key="view.props.name"
    rules="required"
    v-slot="{ errors }"
  >
    <component
      :is="view.component"
      :error-messages="errors[0]"
      v-bind="{ ...$attrs, ...view.props }"
      v-on="{ ...$listeners, ...view.listeners }"
    >
      <template v-for="_, name in $scopedSlots" #[name]="scope">
        <slot :name="name" v-bind="scope"></slot>
      </template>
    </component>
  </ValidationProvider>
</template>

<script>
  import File from './File.vue'
  import Link from './Link.vue'
  import FileItemType from './FileItemType.vue'
  import { FILE_ITEM_TYPE_FILE, FILE_ITEM_TYPE_LINK } from '@/consts/document'

  export default {
    components: {
      FileItemType
    },
    props: {
      type: {
        type: String
      },
      file: {
        type: Object
      },
      link: {
        type: String
      }
    },
    computed: {
      views () {
        return {
          [FILE_ITEM_TYPE_FILE]: {
            component: File,
            props: {
              name: 'file',
              value: this.file
            },
            listeners: {
              input: (e) => this.$emit('update:file', e)
            }
          },
          [FILE_ITEM_TYPE_LINK]: {
            component: Link,
            props: {
              name: 'url',
              value: this.link
            },
            listeners: {
              input: (e) => this.$emit('update:link', e)
            }
          },
        }
      },
      view () {
        return this.views[this.type]
      }
    },
  }
</script>
